function setLanguageCookie(UID, languageID)
{
	// MAKES USE OF jquery AND jquery.cookie
	$.cookie("languageID", languageID, { path: '/', expires: 24 });
	if(UID)
	{
		$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: { contentUID: UID, languageID:  languageID, action: 'getContent'},
		dataType: 'json'
		}).done(function( msg ) {
			if(msg.isHome == 1)
			{
				document.location.href = "/";
			}
			else
			{
				if(msg.deeplink !== null)
				{
					document.location.href = "/"+msg.deeplink;
				}
				else
				{
					document.location.href = "/";
				}
			}
		});
	}
	else
	{
		document.location.href = "/";
	}
}
function login(email, password, callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: {action: "login", email: email, password: password},
		dataType: 'json'
	}).done(callback);
}

function logout(callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: {action: "logout"},
		dataType: 'json'
	}).done(callback);

}

function insertSubscription(lang, email, callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: {action: "insertSubscription", lang: lang, email: email},
		dataType: 'json'
	}).done(callback);
}

function fillCheckoutInputs(user)
{
	for(var item in user)
	{
		if($("#"+item).length)
		{
			$("#"+item).val(user[item]);
		}
	}
	$('#email').attr('disabled', 'disabled');
}
function configFieldsValidation()
{
	$("input, textarea").keyup(function(){
		if(!$(this).val().match(new RegExp($(this).attr('pattern'))))
		{
			$(this).addClass('inputError');
			$(this).parent().find('.error').fadeIn();
		}
		else
		{
			$(this).removeClass('inputError');
			$(this).parent().find('.error').fadeOut();
		}
	})
}
function getForm(formIn)
{

	var form = new Object();
	form.status = true;
	form.data = new Array();
	formElement = $(formIn);
	formElement.find('input, textarea, select').each(function(i){
		var inputType = $(this).attr('type');
		var field = new Object();
		switch(inputType)
		{
			case 'checkbox':
				//
				field.label = ($(this).data('label') != undefined) ? $(this).data('label') : null;
				field.onlabel = ($(this).data('onlabel') != undefined) ? $(this).data('onlabel') : null;
				field.name = $(this).attr('name');


				if($(this).is(":checked") == "on")
		        {
		            field.value = 1;
		        }
		        else
		        {
		            field.value = 0;
        		}

			break;

			case 'radio':
				//

				if($(this).hasClass("mandatory") && !$('input[type=radio][name="'+$(this).attr('name')+'"]:checked').length)
				{
					$(this).addClass('inputError');
					$(this).parent().parent().find('.error').fadeIn();
					form.status = false;
				}
				else
				{
						field.value = $('input[type=radio][name="'+$(this).attr('name')+'"]:checked').val();
						field.label = ($(this).data('label') != undefined) ? $(this).data('label') : null;
						field.onlabel = ($(this).data('onlabel') != undefined) ? $(this).data('onlabel') : null;
						field.name = $(this).attr('name');
						$(this).parent().parent().find('.error').fadeOut();
				}

			break;

			case 'submit':
			case 'hidden':
				//
			break;

			case 'file':
				if($(this).hasClass("mandatory"))
				{
					if(!this.files.length || Number(this.files[0].size) > Number($(this).attr('max-size')))
					{
						$(this).parent().parent().find('.error').fadeIn();
						form.status = false;
					}
					else
					{
						$(this).parent().parent().find('.error').fadeOut();
					}
				}
			break;

			default:
				if($(this).hasClass("mandatory") || $(this).val())
				{
					if(!$(this).val().match(new RegExp($(this).attr('pattern'))))
					{

						$(this).addClass('inputError');
						$(this).parent().find('.error').fadeIn();
						form.status = false;
					}
					else
					{
						field.value = $(this).val();
						field.name = $(this).attr('name');
						$(this).parent().find('.error').fadeOut();

					}
				}


			break;
		}

		//form.data.push(field);

	});
	var data = new FormData(formIn);
	// Handle notes as array
	if ( undefined !== formIn.elements["notes[]"] && formIn.elements["notes[]"].length > 0 ) {
		var notesAsString = '';
		var notesArrayElements = formIn.elements["notes[]"];
		var loopCount = 0;
		for (var i = notesArrayElements.length - 1; i >= 0; i--) {
			var field = notesArrayElements[i];

			// If checked is present and is not true, we dont want that value
			if (
				(
					field.type === 'radio'
					||
					field.type === 'checkbox'
				)
				&& field.checked === false
			) {
				continue;
			}

			++loopCount;

			if ( loopCount > 1 ) {
				notesAsString += ', ';
			}

			notesAsString += field.value;

		};
		data.append("notes", notesAsString);
		// notesAsString should now be included and sent to the php side

	}



	form.data = data;



	return form;

}
/*
function getForm(formElement)
{

	var form = new Object();
	form.status = true;
	form.data = new Array();

	formElement.find('input, textarea, select').each(function(i){
		var inputType = $(this).attr('type');
		var field = new Object();
		switch(inputType)
		{
			case 'checkbox':
				//
				field.value =  $(this).is(":checked");
				field.label = ($(this).data('label') != undefined) ? $(this).data('label') : null;
				field.onlabel = ($(this).data('onlabel') != undefined) ? $(this).data('onlabel') : null;
				field.name = $(this).attr('name');
				form.data.push(field);
			break;

			case 'radio':
				//
				field.value = $('input[type=radio][name="'+$(this).attr('name')+'"]:checked').val();
				field.label = ($(this).data('label') != undefined) ? $(this).data('label') : null;
				field.onlabel = ($(this).data('onlabel') != undefined) ? $(this).data('onlabel') : null;
				field.name = $(this).attr('name');
				var isIn = false;
				for(var i = 0; i < form.data.length; ++i)
				{
					if(form.data[i].name == $(this).attr('name'))
					{
						isIn = true;
						form.data[i] = field;
					}
				}

				if(!isIn)
				{
					form.data.push(field);
				}

			break;

			case 'submit':
				//
			break;

			default:
				if($(this).hasClass("mandatory") || $(this).val())
				{
					if(!$(this).val().match(new RegExp($(this).attr('pattern'))))
					{

						$(this).addClass('inputError');
						$(this).parent().find('.error').fadeIn();
						form.status = false;
					}
					else
					{
						field.value = $(this).val();
						field.name = $(this).attr('name');
						field.label = ($(this).data('label') != undefined) ? $(this).data('label') : null;
					}
				}
				form.data.push(field);
			break;
		}


	});
	return form;
}
*/

function mailchimpSubscribe(subscriberEmail, listID, callback)
{
	if(subscriberEmail)
	{
		var mailChimpListID = listID;
		$.ajax({
			type: "POST",
			url: "/ema/frontend/inc/mailchimp.run.php",
			data: { subscriberEmail: subscriberEmail, mailChimpListID: listID},
			dataType: "json"
		}).done(function( msg ) {
			if(msg.euid)
			{
				getLabelValue('NEWSLETTERSUCCESS', $('body').data('languageid'), callback)
			}
			else
			{
				if(msg.code == 214)
				{
					getLabelValue('NEWSLETTERALREADYSUBSCRIBE', $('body').data('languageid'), callback)
				}
			}
		});
	}
}

// LABELS
function getLabelValue(type, languageID, callback, toUpper)
{

	if(toUpper != undefined)
	{
		toUpper = 1;
	}
	else
	{
		toUpper = 0;
	}

	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/engine.php",
			data: {action: "getLabelValue", type: type, languageID: languageID, toUpper: toUpper},
			dataType: 'json'
	}).done(callback)

}

function getChildren(UID, languageID, callback)
{
	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/engine.php",
			data: {action: "getChildren", UID: UID, languageID: languageID},
			dataType: 'json'
	}).done(callback)
}

function recoverPassword(userEmail, languageID, callback)
{
	var user = new Object();
	user.email = userEmail;

	$.ajax({
			type: "POST",
			url: "/ema/frontend/engine/engine.php",
			data: {action: "recoverPassword", user: user, languageID: languageID},
			dataType: 'json'
	}).done(callback)
}

function sendGenericEmail(emailTo, subject, body, nameFrom, emailFrom, useOperationEmail)
{
	$.ajax({
			type: "POST",
			url: "/ema/frontend/inc/sendGenericEmail.run.php",
			data: { emailTo: emailTo, body: bodyText, subject: subject }
		}).done(function( msg ) {

			if(msg == "1")
			{
				formID.find('#statusMessage').html(formID.data('successmessage'));
			}
			else
			{
				formID.find('#statusMessage').html(formID.data('errormessage'));
			}

			formID.find('#statusMessage').fadeIn();

		});
}

function getUserVOFromFormObj(formIn)
{
	var userVO = new Object();
/*
	userVO.ID = null;
	userVO.firstName = null;
	userVO.lastName = null;
	userVO.birthday = null;
	userVO.email = null;
	userVO.job = null;
	userVO.address = null;
	userVO.zipcode4 = null;
	userVO.zipcode3 = null;
	userVO.zipcodeZone = null;
	userVO.alternativeZipcode = null;
	userVO.shippingContactFirstName = null;
	userVO.shippingContactLastName = null;
	userVO.shippingAddress = null;
	userVO.shippingZipcode4 = null;
	userVO.shippingZipcode3 = null;
	userVO.shippingZipcodeZone = null;
	userVO.shippingAlternativeZipcode = null;
	userVO.shippingCountry = null;
	userVO.shippingContactMobile = null;
	userVO.invoiceFirstName = null;
	userVO.invoiceLastName = null;
	userVO.invoiceNif = null;
	userVO.invoiceAddress = null;
	userVO.invoiceZipcode4 = null;
	userVO.invoiceZipcode3 = null;
	userVO.invoiceZipcodeZone = null;
	userVO.invoiceAlternativeZipcode = null;
	userVO.invoiceCountry = null;
	userVO.phone = null;
	userVO.mobile = null;
	userVO.optional1 = null;
	userVO.optional2 = null;
	userVO.optional3 = null;
	userVO.optional4 = null;
	userVO.optional5 = null;
	userVO.optional6 = null;
	userVO.optional7 = null;
	userVO.optional8 = null;
	userVO.optional9 = null;
	userVO.optional10 = null;
	userVO.nif = null;
	userVO.newsletter = null;
	userVO.partnersPub = null;
	userVO.notes = null;
*/

	for(var i = 0; i < formIn.data.length; ++i)
	{
		userVO[formIn.data[i].name] = formIn.data[i].value;
	}

	if(userVO.shippingSameAsBilling)
	{
		userVO.shippingContactFirstName = userVO.invoiceFirstName;
		userVO.shippingContactLastName = userVO.invoiceLastName;
		userVO.shippingAddress = userVO.invoiceAddress;
		userVO.shippingZipcode4 = userVO.invoiceZipcode4;
		userVO.shippingZipcode3 = userVO.invoiceZipcode3;
		userVO.shippingZipcodeZone = userVO.invoiceZipcodeZone;
		userVO.shippingAlternativeZipcode = userVO.invoiceAlternativeZipcode;
		userVO.shippingCountry = userVO.invoiceCountry;
		userVO.shippingContactMobile = userVO.mobile;

	}

	if(userVO.ID == undefined)
	{
		userVO.firstName = (userVO.firstName == undefined) ? userVO.invoiceFirstName : null;
		userVO.lastName = (userVO.lastName == undefined) ? userVO.invoiceLastName : null;
		userVO.address = (userVO.address == undefined) ? userVO.invoiceAddress : null;
		userVO.alternativeZipcode = (userVO.alternativeZipcode == undefined) ? userVO.invoiceAlternativeZipcode : null;
		userVO.zipcodeZone = (userVO.zipcodeZone == undefined) ? userVO.invoiceZipcodeZone : null;
	}
	return userVO;
}

function registerUser(formIn, callback)
{
	$.ajax({
	    type: 'POST',
	    data: formIn.data,
	    url: '/ema/frontend/engine/engine.php',
	    cache: false,
		contentType: false,
	    processData: false,
	    done:callback,
	    dataType: 'json'
	}).done(callback);
}

function editUserBasicInfo(userVO, callback)
{
	$.ajax({
	    type: 'POST',
	    data: userVO,
	    url: '/ema/frontend/engine/engine.php',
	    cache: false,
		contentType: false,
	    processData: false,
	    done:callback,
	    dataType: 'json'
	}).done(callback);
}

function rateContent(UID, rate, callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: {action: "rateContent", UID: UID, rate: rate},
		dataType: 'json'
	}).done(function(rate){
		callback(rate, UID);
	})
}
function engineInterface(data, callback)
{
	$.ajax({
		type: "POST",
		url: "/ema/frontend/engine/engine.php",
		data: data,
		dataType: 'json'
	}).done(function(response){
		callback(response);
	})
}

function stripTags(string) {
	var strippedString = string.replace(/(<([^>]+)>)/ig,"");
	return strippedString;
}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_-,:;";
    var to   = "aaaaeeeeiiiioooouuuunc______";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


function getFileExtension(filename) {
	
	return filename.split('.').pop();
}
function getFileNameNoExtension(filename) {
	filename = filename.replace(/^.*[\\\/]/, '');
	return filename.split('.').slice(0, -1).join('.');
}
function capitalize(word){
    return word[0].toUpperCase()+word.slice(1).toLowerCase();
}

function shuffleArray(sourceArray) {
	for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i));

        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
} 